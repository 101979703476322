import React, { Component } from "react";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "./Header/Header";
import { MenuMobileDesktop, MenuItem } from "./Header/HeaderStyles";
import Navigation from "../globalComponents/Navigation";
import { Backlink } from "./Backlink";
import Conf from "../Conf";
import Footer from "./Footer/Footer";
import { theme } from "../globalComponents/Theme";
import { getBearer } from "../utils/localStorage";
import { withLanguage } from "../context/LanguageContext/withLanguage";

/**
 * Some black magic to handel non-deep-linked view back actions
 * @todo make a proper solution or remove after implantation of all deep links
 * @type {null}
 */
window.customBackAction = null;

/**
 * Layout Component
 */
class Layout extends Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();

    this.state = { isToggleOn: false, height: 0 };
    this.handleClick = this.handleClick.bind(this);
    this.getHeight = this.getHeight.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({ isToggleOn: !prevState.isToggleOn }));
  }

  getHeight() {
    if (this.titleRef.current)
      this.setState({ height: this.titleRef.current.clientHeight });
  }

  componentDidMount() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getHeight);
    window.addEventListener("resize", this.getWidth);
  }

  render() {
    let authLayout = getBearer();
    if (this.props.resetPassword || this.props.renewPassword) {
      authLayout = false;
    }

    const Wrapper = styled("div")`
      height: 100%;
      padding: ${(props) => (props.height ?? 0) + 62}px 0 70px;

      @media screen and (max-width: 489px) {
        padding: ${(props) =>
            authLayout && props.searchActive
              ? `${101 + (props.height ?? 0)}px`
              : "112px"}
          0 70px;
      }

      @media screen and (min-width: 993px) {
        padding: ${() => (authLayout ? "163" : "112")}px 0 52px;
      }

      @media screen and (max-height: 768px) and (min-width: 1024px) {
        padding-bottom: 0;
      }

      /* tablet */
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
        padding: ${(props) => (props.height ?? 0) + 62}px 0 75px;
      }
    `;

    const TitleElementLeft = styled("div")`
      top: 0;
      min-width: 30px;
    `;

    const TitleElementRight = styled("div")`
      padding: 5px 0;
      top: 0;
      right: 15px;
      line-height: 40px;
      height: 50px;
      min-width: 30px;
      svg {
        margin-top: 8.75px;
        height: 22.5px;
      }
    `;

    const TitleElementMiddle = styled("div")`
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      margin: auto;
      padding: 5px 15px;
    `;

    const TitleContainer = styled("div")`
      display: flex;
      align-items: center;
      min-height: 50px;
      height: 100%;
      padding: 0 15px !important;
      width: 100% !important;
      position: relative;

      @media screen and (min-width: 1200px) {
        width: 1190px !important;
        padding: 0 5px !important;
      }
    `;

    function SiteTitle({ children }) {
      return <TitleElementMiddle>{children}</TitleElementMiddle>;
    }

    const Title = styled("div")`
      background-color: #ffffff;
      border-bottom: 1px solid #d4d4d4;
      height: auto;
      position: fixed;
      top: ${(props) => (authLayout && props.searchActive ? "101px" : "62px")};
      width: 100%;
      z-index: 90;

      @media screen and (min-width: 490px) {
        top: 62px;
      }

      @media screen and (min-width: 993px) {
        top: ${() => (authLayout ? "113" : "62")}px;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
        top: 62px !important;
      }
    `;

    const MenuTablet = styled(MenuMobileDesktop)`
      display: none !important;

      @media screen and (min-width: 768px) and (max-width: 992px) {
        border-bottom: 0;
        border-left: 1px solid #d4d4d4;
        display: block !important;
        height: auto;
        max-height: 0;
        position: absolute;
        right: 0;
        top: 62px;
        width: 25%;
        z-index: 95;

        &.active {
          border-bottom: 1px solid #d4d4d4;
          max-height: 500px;
        }
      }
    `;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper
          searchActive={this.props.modules.includes("SEARCH")}
          height={this.state.height}
        >
          <Helmet
            titleTemplate={`%s - ${this.props.intl.formatMessage({
              id: "app.title",
            })}`}
            defaultTitle={this.props.intl.formatMessage({ id: "app.title" })}
          >
            <meta
              name="application-name"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
            <meta
              name="msapplication-tooltip"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
            <meta
              name="apple-mobile-web-app-title"
              content={this.props.intl.formatMessage({ id: "app.title" })}
            />
          </Helmet>
          <Header
            {...this.props}
            className="header"
            isToggleOn={this.state.isToggleOn}
            func={this.handleClick}
            resetPassword={this.props.resetPassword}
          />{" "}
          {authLayout && (
            <MenuTablet className={this.state.isToggleOn ? "active" : ""}>
              {Conf.basicLinks.map((link) => (
                <MenuItem key={link.key} url={link.route}>
                  <FormattedMessage id={link.name} />
                </MenuItem>
              ))}
              {this.props.languageContext.selectionAvailable && (
                <MenuItem key="1" url="/language">
                  <FormattedMessage id="app.global.language" />
                </MenuItem>
              )}
              {this.props.resetPassword ? (
                <MenuItem key="0" url="/login">
                  <FormattedMessage id="app.global.backToLogin" />
                </MenuItem>
              ) : (
                <MenuItem key="0" url="/logout">
                  <FormattedMessage id="app.global.logout" />
                </MenuItem>
              )}
            </MenuTablet>
          )}
          {authLayout && <Navigation {...this.props} />}
          <Title
            className="title"
            searchActive={this.props.modules.includes("SEARCH")}
            ref={this.titleRef}
          >
            <TitleContainer className="container">
              <TitleElementLeft>
                {!this.props.hideBacklink && (
                  <Backlink goBack={this.props.goBack} />
                )}
              </TitleElementLeft>
              <SiteTitle
                controls={!!this.props.controls}
                backlink={!this.props.hideBacklink}
              >
                {this.props.tooltip ? (
                  <span title={this.props.tooltip} style={{ cursor: "help" }}>
                    {this.props.title}
                  </span>
                ) : (
                  this.props.title
                )}
              </SiteTitle>
              <TitleElementRight>{this.props.controls}</TitleElementRight>
            </TitleContainer>
          </Title>
          {this.props.children}
          <Footer />
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default withLanguage(injectIntl(Layout));
